* {
  box-sizing: border-box;
  user-select: none;
}

html {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  background: #000;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
}
